import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faBicycle, faSuitcase, faPersonRunning } from "@fortawesome/free-solid-svg-icons";
import './Plans.css';

export default function Plans() {
    return (
        <div className="plans">
            <div className="hero plans-hero">
                <div className="hero-content">
                    <h1>Membership Plans</h1>
                </div>
            </div>
            <div className="memberships light">
                <h1>Our Membership Plans</h1>
                <p className="membership-desc">
                    Whether you want a monthly membership, access to a single class or to bring a friend for a workout, you can choose a membership plan
                    that fits your goals.
                    <br></br>
                    A 4-week written notice is required to cancel a membership.
                    <br></br>
                    We are an adult-only gym, members have to be 18 years old and above.
                </p>
                <h1>Monthly Plans</h1>
                <div className="plans-wrapper">
                    <div className="membership-card light-card">
                        <div className="membership-title">
                            <FontAwesomeIcon icon={faDumbbell} className="card-icon" />
                            <h3>Full Membership</h3>
                            <p>£40 Monthly Direct Debit</p>
                        </div>
                        <div className="membership-perks">
                            <p>Access to the entire gym</p>
                            <p>Fitness Classes</p>
                            <p>Gym Induction</p>
                        </div>
                        <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Sign Up</a>
                    </div>
                    <div className="membership-card light-card">
                        <div className="membership-title">
                            <FontAwesomeIcon icon={faBicycle} className="card-icon" />
                            <h3>Students, NHS & over 60s</h3>
                            <p>£32.50 Monthly Direct Debit</p>
                        </div>
                        <div className="membership-perks">
                            <p>Proof of ID required</p>
                            <p>Access to the entire gym</p>
                            <p>Fitness Classes</p>
                            <p>Gym Induction</p>
                        </div>
                        <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Sign Up</a>
                    </div>
                    <div className="membership-card light-card">
                        <div className="membership-title">
                            <FontAwesomeIcon icon={faSuitcase} className="card-icon" />
                            <h3>Corporate</h3>
                            <p>£37.50 Monthly</p>
                            <p>6 Months - £225 up front</p>
                            <p>12 Months - £450 up front</p>
                        </div>
                        <div className="membership-perks">
                            <p>At least 10 employees to qualify</p>
                            <p>Access to the entire gym</p>
                            <p>Fitness Classes</p>
                            <p>Complimentary Fitness Consultation</p>
                            <p>Gym Induction</p>
                        </div>
                        <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Sign Up</a>
                    </div>
                </div>
                <h1 style={{marginTop: "4rem"}}>Day Passes</h1>
                <div className="plans-wrapper">
                    <div className="membership-card light-card">
                        <div className="membership-title">
                            <FontAwesomeIcon icon={faPersonRunning} className="card-icon" />
                            <h3>Day Pass</h3>
                            <p>£7.50/day</p>
                        </div>
                        <div className="membership-perks">
                            <p>Full gym access</p>
                            <p>Access to Fitness Class</p>
                            <p>Payment at the reception</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}