import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { data } from "./data";
import './Profile.css';

export default function Adam() {

    return (
        <div className="profile">
            <div className="profile-container">
                <div className="profile-content">
                    <Link to="/trainers" className="button"><FontAwesomeIcon icon={faArrowLeft}/></Link>
                    <h1 className="services-heading">{data[2].name}</h1>
                    <p className="topline">{data[2].title}</p>
                    <p className="services-feature">{data[2].description}</p>
                    {data[2].area_of_expertise.length > 0 &&
                        <div className="desc-list">
                            <h1>Areas Of Expertise</h1>
                            {
                                data[2].area_of_expertise.map(area =>
                                    <p className="services-feature"><FontAwesomeIcon icon={faCircleCheck} className="circle-icon" /> {area}</p>
                                )
                            }
                        </div>}
                    <div className="desc-list">
                        <h1>Qualifications</h1>
                        {
                            data[2].qualification.map(qual =>
                                <p className="services-feature"><FontAwesomeIcon icon={faCircleCheck} className="circle-icon" /> {qual}</p>
                            )
                        }
                    </div>
                </div>
                <img src={data[2].img} alt="trainer-img" />
            </div>
        </div>
    );
}