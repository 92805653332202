import React from "react";
import Timetable from "./timetable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import './Services.css';

export default function Services() {
    return (
        <>
            <div className="hero services-hero">
                <div className="hero-content">
                    <h1>Services</h1>
                </div>
            </div>
            <div className="section">
                <h1>Fitness Classes</h1>
                <p>
                    Whether you want to lose weight, tone up or improve strength, we have an excellent  selection of studio classes for all abilities,
                    from Box-Fit and Spin Circuit to conditioning classes including Legs, Bums and Tums, Body Blast, Kettlebells and Ab Attack. We also
                    have a Yoga class where you can relax your mind and body.
                    <br></br>
                    Check out our class timetable.
                </p>
                <p>Book into classes through the FitSense app available on the App Store and Google PlayStore.</p>
                <div className="download">
                    <a className="button" href="https://itunes.apple.com/us/app/fitsense/id1134519201" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faApple} /></a>
                    <a className="button" href="https://play.google.com/store/apps/details?id=clubwise.com.fitsense" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faAndroid} /></a>
                </div>
                <Timetable />
            </div>
            <div className="section dark">
                <h1>Personal Training</h1>
                <p>
                    All our personal trainers have over 30 years of experience combined.
                    Each trainer specialises in strength & conditioning, and mobility training.
                    A personal trainer will motivate you to push your limits, hold you accountable, design a good training plan to help you
                    acheive your goals.
                </p>
                <p>
                    If you are new to the gym environment and don't know where to start, or you have gym experience and want to take it to the next level,
                    feel free to get in touch with any of our trainers.
                </p>
                <p>We are here to help you acheive your health and fitness goals.</p>
                <Link to="/trainers" className="button">Trainers</Link>
            </div>
            <div className="section">
                <h1>Massage Therapy</h1>
                <p>
                    Why not come and visit us for a massage. We have two therapists offering deep tissue/sports massage as well as other sports therapy
                    treatments.
                </p>
                <p>
                    Email <a className="anchor" href="mailto:Roy@physical-kal.co.uk" rel="noopener noreferrer" target="_blank">Roy@physical-kal.co.uk</a> for more information.
                </p>
            </div>
            <div className="section dark">
                <h1>PhysioBAB</h1>
                <p>
                    We aim to improve mobility, strength, to relieve pain, and to restore physical function.
                </p>
                <p>
                    We offer physiotherapy sessions and one-on-one pilates classes. We also provide different types of massages including swedish, deep tissue,
                    sports and pregnancy.
                </p>
                <p>
                    Corporate massages are provided for employees at their workplace, and can include both seated massage and table massage.
                    Corporate massages are proven to improve mood, reduce stress, and provide an added reason to work for the company.
                </p>
                <p>
                    Visit <a className="anchor" href="https://www.physiobab.com" rel="noopener noreferrer" target="_blank">physiobab.com</a> for more information.
                </p>
                <p>
                    Get in touch via email at <a className="anchor" href="mailto:physiobab@gmail.com" rel="noopener noreferrer" target="_blank">physiobab@gmail.com</a> or call 07411999758.
                </p>
            </div>
            <div className="section">
                <h1>Park Barbers</h1>
                <p>
                    Want a sharp new hair cut? Need your beard trimmed? Pop over to the Trinity Centre and see the Park Barbers, Charlie and Antonio.
                    They have years of experience, and they cater for all hair types. If you want to look sharp come to the park.
                </p>
                <p>Book an appointment <a className="anchor" href="https://booksy.com/en-gb/48267_park-barbers_barber-shop_148251_cambridge" rel="noopener noreferrer" target="_blank">booksy.com</a> or call 07514 075949.</p>
            </div>
            <div className="section dark">
                <h1>Brazilian Top Team Cambridge</h1>
                <p>
                    At Brazilian Top Team Cambridge, we offer high-level instruction in Brazilian Jiu Jitsu with classes that fit your schedule at accessible pricess, whether you are training for fun, competition or fitness.
                    Forge a stronger you through BJJ.
                </p>
                <p>
                    Visit <a className="anchor" href="https://www.bttcambridge.com" rel="noopener noreferrer" target="_blank">bttcambridge.com</a> for more information.
                </p>
            </div>
        </>
    );
}