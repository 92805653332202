import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './ServiceSection.css';
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

export default function Services() {
    return (
        <>
            <div className="services">
                <div className="services-container">
                    <div>
                        <p className="topline">Services</p>
                        <h1 className="services-heading">What We Offer</h1>
                        <div className="services-features">
                            <p className="services-feature">
                                <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/> Fitness Classes
                            </p>
                            <p className="services-feature">
                                <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/> Personal Training
                            </p>
                            <p className="services-feature">
                                <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/> Physio Therapy
                            </p>
                            <p className="services-feature">
                                <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/> Massage Therapy
                            </p>
                            <p className="services-feature">
                                <FontAwesomeIcon icon={faCircleCheck} className="circle-icon"/> Beauty Therapy
                            </p>
                        </div>
                    </div>
                    <div>
                        <img src="./images/img1.jpg" alt="gym-floor" className="services-img" />
                    </div>
                </div>
            </div>
        </>
    );
}