import React from "react";
import HeroSection from "./HeroSection";
import ServiceSection from "./ServiceSection";
import MembershipSection from "./MembershipSection";
import TrainerSection from "./TrainerSection";

export default function Home() {
    return (
        <>
            <HeroSection/>
            <ServiceSection/>
            <MembershipSection/>
            <TrainerSection/>
        </>
    );
}