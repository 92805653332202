import React from "react";
import './Footer.css';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-wrapper">
                <div className="footer-desc">
                    <h1>Revolution Health & Fitness</h1>
                    <p>24 Cambridge Science Park Milton Rd, Milton, Cambridge CB4 0FN</p>
                    <p id="email">info@revolutionhealthfitness.co.uk</p>
                    <p id="phone">01223 395675</p>
                </div>
                <div className="footer-links">
                    <h2 className="footer-title">Opening Times</h2>
                    <p>Monday-Friday: 07:00 - 20:00</p>
                    <p>Saturday/Sunday/Bank holidays: closed</p>
                </div>
            </div>
            <div className="footer-wrapper">
                <div className="footer-links">
                    <h2 className="footer-title">Join Us</h2>
                    <Link className="footer-link" to="/services">Services</Link>
                    <Link className="footer-link" to="/plans">Plans</Link>
                    <Link className="footer-link" to="/trainers">Trainers</Link>
                </div>
                <div className="footer-links">
                    <h2 className="footer-title">Social Media</h2>
                    <Link className="footer-link" to="https://www.instagram.com/revolutionhealthfitness" rel="noopener noreferrer" target="_blank">Instagram</Link>
                    <Link className="footer-link" to="https://www.facebook.com/Revolution-Health-Fitness-Club-1460049640917127" rel="noopener noreferrer" target="_blank">Facebook</Link>
                    <Link className="footer-link" to="https://twitter.com/revolutionfit24" rel="noopener noreferrer" target="_blank">X</Link>
                </div>
            </div>
        </div>
    );
}