import React from "react";
import TrainerSection from "../Home/TrainerSection";
import './Trainers.css';

export default function Trainers() {
    return (
        <div className="trainers-page">
            <div className="hero trainers-hero">
                <div className="hero-content">
                    <h1>Personal Trainers</h1>
                </div>
            </div>
            <TrainerSection />
        </div>
    );
}