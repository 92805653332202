import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../Components/ScrollToTop';
import Navbar from '../Components/Navbar';
import Home from '../Components/Home';
import Services from '../Components/Services';
import Trainers from '../Components/Trainers';
import Plans from '../Components/Plans';
import Rez from '../Components/Profile/rez';
import Roy from '../Components/Profile/roy';
import Adam from '../Components/Profile/adam';
import Rhys from '../Components/Profile/rhys';
import Footer from '../Components/Footer';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/plans' element={<Plans />} />
          <Route path='/trainers' element={<Trainers />} />
          <Route path='/rez' element={<Rez />} />
          <Route path='/roy' element={<Roy />} />
          <Route path='/adam' element={<Adam />} />
          <Route path='/rhys' element={<Rhys />} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
