import React from "react";
import './HeroSection.css';

export default function HeroSection() {
    return (
        <div className="hero">
            <div className="hero-content">
                <h1>Revolution Health & Fitness</h1>
                <p>Join Now for £40/month</p>
                <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Get Started</a>
            </div>
        </div>
    );
}