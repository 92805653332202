import React from "react";
import './TrainerSection.css';
import { Link } from "react-router-dom";

export default function TrainerSection() {
    return (
        <div className="trainers">
            <div className="trainers-wrapper">
                <div className="trainers-text">
                    <p className="topline">Team</p>
                    <h1>Meet Our Trainers</h1>
                    <p className="trainers-desc">
                        All our personal trainers have over 30 years of experience combined.
                        Each trainer specialises in strength & conditioning, and mobility training.
                    </p>
                </div>
                <div className="trainers-text">
                    <p className="topline">Personal Training</p>
                    <h1>Private Coaching</h1>
                    <p className="trainers-desc">
                        Let one of our highly qualified trainers set you a realistic and achievable programme for the gym and studio.
                    </p>
                </div>
                <div className="trainer-card">
                    <Link to="/rez">
                        <p>Rez</p>
                        <img src="./images/trainer1.jpg" alt="trainer" className="trainer-img" />
                    </Link>
                </div>
                <div className="trainer-card">
                    <Link to="/adam">
                        <p>Adam</p>
                        <img src="./images/trainer3.jpg" alt="trainer" className="trainer-img" />
                    </Link>
                </div>
                <div className="trainer-card">
                    <Link to="/roy">
                        <p>Roy</p>
                        <img src="./images/trainer4.jpg" alt="trainer" className="trainer-img" />
                    </Link>
                </div>
                <div className="trainer-card">
                    <Link to="/rhys">
                        <p>Rhys</p>
                        <img src="./images/trainer5.jpg" alt="trainer" className="trainer-img" />
                    </Link>
                </div>
            </div>
        </div>
    );
}