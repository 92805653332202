import React from "react";

export default function Timetable() {
    return (
        <div className='timetable'>
            <div>
                <table>
                    <tr>
                        <td>
                            <h4>Mon</h4>
                        </td>
                        <td>
                            07:15 - 07:45<br></br>
                            12:15 - 12:45<br></br>
                            17:30 - 18:15
                        </td>
                        <td>
                            Circuits<br></br>
                            Body Conditioning<br></br>
                            Circuits
                        </td>
                        <td>
                            Adam<br></br>
                            Roy<br></br>
                            Rhys
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Tue</h4>
                        </td>
                        <td>
                            07:15 - 07:45<br></br>
                            12:15 - 12:45<br></br>
                            17:30 - 18:00
                        </td>
                        <td>
                            Kettlebells<br></br>
                            Pump Revolution<br></br>
                            Legs, Bums and Tums
                        </td>
                        <td>
                            Adam<br></br>
                            Rez<br></br>
                            Roy
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Wed</h4>
                        </td>
                        <td>
                            07:15 - 07:45<br></br>
                            12:15 - 12:45<br></br>
                            17:30 - 18:00
                        </td>
                        <td>
                            Legs, Bums and Tums<br></br>
                            Ab Attacks<br></br>
                            HIIT
                        </td>
                        <td>
                            Rez<br></br>
                            Roy<br></br>
                            Rhys
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Thur</h4>
                        </td>
                        <td>
                            07:15 - 07:45<br></br>
                            12:15 - 12:45<br></br>
                            17:30 - 18:00
                        </td>
                        <td>
                            Body Blast<br></br>
                            Box - Fit<br></br>
                            Kettlebells
                        </td>
                        <td>
                            Adam<br></br>
                            Rez<br></br>
                            Rez/Rhys
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h4>Fri</h4>
                        </td>
                        <td>
                            07:15 - 07:45<br></br>
                            12:15 - 12:45<br></br>
                            12:15 - 13:15
                        </td>
                        <td>
                            Ab Attack<br></br>
                            Small Group Training<br></br>
                            Yoga
                        </td>
                        <td>
                            Adam<br></br>
                            Rez<br></br>
                            Gabriela
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}