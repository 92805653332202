import React from "react";
import './MembershipSection.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faBicycle, faSuitcase } from "@fortawesome/free-solid-svg-icons";

export default function MembershipPlans() {
    return (
        <div className="memberships">
            <h1>View Our Plans</h1>
            <p className="membership-desc">
                Join today and acheive your health and fitness goals.
                <br></br>
                One month's written notice required to cancel a membership.
                <br></br>
                We are an adult-only gym, members have to be 18 years old and above.
            </p>
            <div className="membership-wrapper">
                <div className="membership-card">
                    <div className="membership-title">
                        <FontAwesomeIcon icon={faDumbbell} className="card-icon" />
                        <h3>Full Membership</h3>
                        <p>£40 Monthly Direct Debit</p>
                    </div>
                    <div className="membership-perks">
                        <p>Access to the entire gym</p>
                        <p>Fitness Classes</p>
                        <p>Gym Induction</p>
                    </div>
                    <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Sign Up</a>
                </div>

                <div className="membership-card">
                    <div className="membership-title">
                        <FontAwesomeIcon icon={faBicycle} className="card-icon" />
                        <h3>Students, NHS & over 60s</h3>
                        <p>£32.50 Monthly Direct Debit</p>
                    </div>
                    <div className="membership-perks">
                        <p>Proof of ID required</p>
                        <p>Access to the entire gym</p>
                        <p>Fitness Classes</p>
                        <p>Gym Induction</p>
                    </div>
                    <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Sign Up</a>
                </div>


                <div className="membership-card">
                    <div className="membership-title">
                        <FontAwesomeIcon icon={faSuitcase} className="card-icon" />
                        <h3>Corporate</h3>
                        <p>£37.50 Monthly</p>
                        <p>6 Months - £225 up front</p>
                        <p>12 Months - £450 up front</p>
                    </div>
                    <div className="membership-perks">
                        <p>At least 10 employees to qualify</p>
                        <p>Access to the entire gym</p>
                        <p>Fitness Classes</p>
                        <p>Complimentary Fitness Consultation</p>
                        <p>Gym Induction</p>
                    </div>
                    <a href="https://secure17.clubwise.com/revolutionhealthandfitnesscentre/pos.asp" className="button" rel="noopener noreferrer" target="_blank">Sign Up</a>
                </div>
            </div>
        </div>
    );
}