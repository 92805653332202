import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Navbar.css';

export default function Navbar() {
    const [menuClick, setMenuClick] = useState(false);

    const handleMenuClick = () => setMenuClick(!menuClick);
    const closeMobileMenu = () => setMenuClick(false);

    return (
        <nav className="navbar">
            <Link to="/" className="navbar-logo">Revolution Health & Fitness</Link>
            <div className={menuClick ? "navbar-toggle is-active" : "navbar-toggle"} id="mobile-menu" onClick={handleMenuClick}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <div className={menuClick ? "navbar-menu active" : "navbar-menu"}>
                <Link to="/" className="navbar-link" onClick={closeMobileMenu}>Home</Link>
                <Link to="/services" className="navbar-link" onClick={closeMobileMenu}>Services</Link>
                <Link to="/plans" className="navbar-link" onClick={closeMobileMenu}>Plans</Link>
                <Link to="/trainers" className="navbar-link" onClick={closeMobileMenu}>Trainers</Link>
            </div>
        </nav>
    );
}