const data = [
    {
        name: "Rez Ahmed",
        title: "Gym Manager",
        description: "Rez has been working in the fitness industry for over 10 years. He started off as a personal trainer then as a manager for big fitness chains. Rez has a passion for health and fitness and his motto is 'Inspiring people to live a fitter lifestyle'. Rez has competed in martial arts and he is also a former competitive bodybuilder. What ever your reasons may be for joining, Rez is here to help and encourage you to make the most of your time at Revolution Health and Fitness.",
        qualification: ["Level 3 Diploma in Personal Training", "Level 4 Lower Back Pain Management", "Kettlebell Trainer", "Sports Massage Therapist"],
        area_of_expertise: [],
        mobile: "",
        email: "info@revolutionhealthfitness.co.uk",
        img: "/images/trainer1.jpg"
    },
    {
        name: "Roy Essandoh",
        title: "Personal Trainer and Sports Therapist",
        description: "",
        qualification: ["Advanced Diploma in Personal training", "L5 Clinical/remedial sports therpist", "Accredited Strength & conditioning coach", 
            "Diploma in Nutrition/Applied sport", "UKSCA Olympic lifting for sport performance/SAQ", "First Aid"],
        area_of_expertise: ["Weight training", "Improving sports performance", "Massage therapy/Sport therapy", "Postural assessment/rehab"],
        mobile: "07437200707",
        email: "roy@physi-kal.co.uk",
        img: "/images/trainer4.jpg"
    },
    {
        name: "Adam Cook",
        title: "Personal Trainer and Fitness Coach",
        description: "",
        qualification: ["Level 3 Personal Trainer", "Exercise Referal Instructor", "Group Exercise Instructor"],
        area_of_expertise: ["Body Composition & Body Weight Management", "Strength Training and Power Lifting", "Nutrition Advice", "Power Lifting Competition Prep"],
        mobile: "07794518232",
        email: "adamcookpt@gmail.com",
        img: "/images/trainer3.jpg"
    },
    {
        name: "Rhys Webb",
        title: "Personal Trainer and Fitness Coach",
        description: "Rhys has a passion for health and fitness and enjoys teaching Group Fitness classes. Rhys is always on the gym floor motivating members.",
        qualification: ["Level 3 Personal Trainer", "Level 2 Fitness Instructor", "Circuit Trainer", "Kettlebell Instructor"],
        area_of_expertise: [],
        mobile: "",
        email: "",
        img: "/images/trainer5.jpg"
    }
];

module.exports = { data };